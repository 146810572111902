.item-title {
    color: #999999;
    padding: 5px;
}

.item-number {
    color: #999999;
    padding: 5px;
}

.contact-section {
    width: 100vw;
    height: 30vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.brouchure-section {
    width: 50vw;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.specific-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px;
}

.brouchure-container {
    width: 100%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
}

.brouchimage1 {
    width: 63vw;
    height: 30vh;
    margin-top: 0px;
    position: absolute;
}

.brouchimage2 {
    width: 33vw;
    height: 30vh;
    margin-left: 0px;
    position: absolute;
}

.contact-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding: 30px;
}

.brouchure-text {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}

.head-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    left: 15px;
}

.brouchure-btn {
    font-weight: bold;
    border-radius: 10px;
    color: #FFF;
    border-color: #FFA537;
    background-color: #FFA537;
}

.card {

    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

@media only screen and (max-width: 700px) {
    .specific-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 15px;
    }

    .brouchure-container {
        width: 100%;
        height: 80%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }

    .brouchimage1 {
        padding: 10px;
        width: 100%;
        height: 30vh;
        margin-left: 0px;
        position: absolute;
    }

    .brouchimage2 {
        padding: 10px;
        width: 100%;
        height: 30vh;
        margin-left: 0px;
        position: absolute;
    }

    .contact-text {
        right: 15px;
    }

    .head-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        /* padding: 50px; */
    }

    .contact-section {
        width: 100vw;
        height: 30vh;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .brouchure-section {
        width: 100vw;
        height: 30vh;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}