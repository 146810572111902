
.container-style {
    height: 100%;
    position: relative;
    text-align: center;
    font-weight: bold;
    color: #515151;
    padding: 20px;
}

.main-box {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.title-card {
    width: 80%;
    margin: 0;
    background-color: #FFA433;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.zoom:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.main-product {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.product-image {
    background-image: url("../../../assets/ProductImages/Rectangle.png");
    width: 250px;
    position: relative;
}

.img-content {
    width: 250px;
    height: 200px;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    color: #FFF;
    font-weight: bold;
}

.img-content:hover {
    opacity: 1;
}

.arrow {
    .rec.rec-arrow:hover {
        background-color: #FFF;
        color: black;
    }
}
.rec.rec-arrow:focus,
.rec.rec-arrow:hover {
   background-color: #FFF !important;
   color: #000 !important;
}

/* Material Section */
.materials-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.view-products {
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
    padding: 5px;
}
.product-lists {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    width: 50%;
    height: 50%;
}
.scroll-bar {
    background-color: #FFA433; 
    width: 30vw; 
    height: 70vh; 
    padding: 10px; 
    border-radius: 10px;
    overflow-y: scroll;
}
.scroll-bar::-webkit-scrollbar {
    display: none;
}
.scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.product-details {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    width: 50%;
    height: 50%;
}
.details-bar {
    width: 38vw;
    height: 70vh;
    overflow-y: scroll;
}

.details-bar::-webkit-scrollbar {
    display: none;
}
.details-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.product-details img {
    width: 35vw;
    height: 50vh;
}
/* .product-heading {
    float: right;
    flex-direction: column;
    justify-content: flex-start;
} */




.shop-container {
    /* background-image: url("../assets/shoppage/shopimg.png"); */
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: row;
    /* height: 50vh;  */
    /* position: absolute; */
    /* padding: 10px; */
}
.shop-image img {
    width: 100%;
    height: 75vh;
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 5px;
    /* object-fit: cover; */
}
.shop-text {
    width: 50%;
    color: #FFF;
    font-weight: bold;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
}
.show-btn{
    display: flex;
    flex-direction: row;
    align-self: start;
    padding: 10px;
}

/* Request Section */
.request-section {
    /* background-image: url(../assets/MailsectionImg/mailbgimg.png); */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* position: absolute; */
}
.request-card {
    width: 50vw;
    height: 83vh;  
    background-color: #FFF;
    border: 5px solid #F96F08;
    border-radius: 50px;
    position: absolute;
}


@media only screen and (max-width: 700px) {
    .main-box {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .zoom image {
        width: 80%;
    }

    .zoom:hover {
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .container {
        display: grid;
        grid-template-columns: auto;
    }

    .main-product {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
    }
}