* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  width: 100vw;
  background-color: #fff;
  color: #fff;
}

.mainlogo {
  /* font-size: 30px; */
  width: 150px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 75%;
}

.home,
.services,
.emart,
.contact,
.login {
  text-decoration: none;
  color: #000;
  font-size: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  /* animation-duration: 1s; */
  /* animation-iteration-count: infinite; */
  /* transform-origin: bottom; */
}

.home:hover,
.services:hover,
.emart:hover,
.contact:hover {
  outline: 3px solid #F08D32;
  border-radius: 30px;
  /* animation-name: bounce; */
  /* animation-timing-function: ease; */
}

.login {
  border: 4px solid #F08D32;
  border-radius: 30px;
}

.mobile-menu-icon {
  display: none;
}

.login-btn {
  background-image: url("../../assets/sub-logos/loginbg.jpg");
  color: #FFFFFF;
  border-radius: 100px;
}

.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  /* // removes default disc bullet for li tags and applies margin to left & right side */
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  /* // increases the surface area of the anchor tag to span more than just the anchor text */
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  /* // removes default border on button element */
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

@media screen and (max-width: 960px) {

  /* .logo{
        display: flex;
        position: absolute;
        top: 15px;
        left: 35px;
    } */
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    background-color: #F18E33;
    left: 0;
    top: 75px;
    transition: all 0.5s ease-out;
    width: 100%;
  }

  .home,
  .services,
  .emart,
  .contact,
  .login {
    color: #000;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .login {
    border: none;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    color: #fff;
    background-color: #00828c;
    border: none;
    outline: none;
    /* top: 15px; */
    /* right: 25px; */
  }
}

/* navbar.css */
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    /* navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
    position: absolute;
    top: 60px;
    left: 0;
    /* stacks the li tags vertically  */
    flex-direction: column;
    /* // makes menu span full height and width */
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }

  .navigation-menu li {
    /* // centers link text and strips off margin */
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    /* // increases the surface area of the anchor tag to span the full width of the menu */
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
}

/* @keyframes bounce {
  0%   { transform: translateY(0); }
  5%  { transform: translateY(-5px); }
} */