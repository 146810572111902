.contact-page {
    padding: 15px;
    width: 100%;
    height: 88vh;
    background-color: #FFA433;
    display: flex;
    flex-direction: row;
    /* column-count: 3; */
}

.contact-box {
    /* height: 350px; */
    padding: 15px;
    width: 33.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

div .media-links {
    width: 290px;
    height: 25vh;
    column-gap: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

/* .contact-box2 {
    padding: 15px;
    width: 33.5%;
    height: 100%;
    display: flex; 
    flex-direction: column; 
    justify-content: space-around;
 } */
/* .contact-box3 {
    padding: 15px; 
    width: 33.5%; 
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-around;
 } */
h4 {
    color: #FFFFFF;
}

.contact-box1 h6 .our-pages {
    font-weight: bold;
}

.copyright-section {
    padding: 15px;
    width: 100%;
    background-color: #FFA433;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {

    /* For mobile phones: */
    .contact-page {
        padding: 15px;
        width: 100%;
        height: 100%;
        background-color: #FFA433;
        display: flex;
        flex-direction: column;
        column-count: 3;
    }

    .contact-box {
        padding: 15px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    div .media-links {
        width: 290px;
        height: 20vh;
        column-gap: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    h4 {
        color: #FFFFFF;
    }

    h6 {
        font-weight: bold;
        color: #000000;
    }

    /* .contact-box2 {
        padding: 15px;
        width: 100%;
        height: 100%;
        display: flex; 
        flex-direction: column; 
        justify-content: space-around;
     }  */
    /* .contact-box3 {
        padding: 15px; 
        width: 100%; 
        height: 100%; 
        display: flex; 
        flex-direction: column; 
        justify-content: space-around;
     } */
    .copyright-section {
        padding: 18px;
        width: 100%;
        background-color: #FFA433;
        display: flex;
        justify-content: center;
    }
}